import React, { useEffect, useState } from "react";
import "./IncomeOutgoingCalcTable/style.css";
import FormContent from "./Form/FormContent";
import { useSelector, useDispatch } from "react-redux";
import store, { RootState, AppDispatch } from "../redux/store";
import { setFormData, resetFormData } from "../redux/reducer";
import { setDialogModalOptions } from "../redux/modelDialogReducer";
import { formBuilder } from "../utils/formGenerator";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  tokens,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Label,
  Card,
  CardHeader,
  CardPreview,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  EyeFilled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  DismissCircleRegular,
  DismissCircle20Regular,
  Circle24Regular,
  Add16Filled,
} from "@fluentui/react-icons";
import TableDataList from "./TableDataList";
import apiServices from "../service";
import {
  checkIfKeyExist,
  checkUserPermission,
  convertStringToArray,
  formatedDate,
  formatedDate2,
  formatedTime,
  getClientCat,
  getClientNoteCategories,
  getClientType,
  getYes_NoConversion,
  isObjectValue,
} from "../utils";
import axios from "axios";
import IncomeCalcTable from "./IncomeOutgoingCalcTable/IncomeCalcTable";
import OutgoingCalcTable from "./IncomeOutgoingCalcTable/OutgoingCalcTable";
import { UserPermissionsList } from "../config";
import LoadStandardAction from "./LoadStandardAction";
import OverlayLoader from "./OverlayLoader";
import { FormErrorMessage } from "./FormErrorMessage/FormErrorMessage";
import PartnerDetailPopup from "./PartnerDetailPopup/PartnerDetailPopup";

const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
    marginTop: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type FormContainerWithListProps = {
  [x: string]: any;
};

const FormContainerWithList = (props: FormContainerWithListProps) => {
  let { context } = props;
  const classes = useStyles();
  const [nextAction, setNextAction] = useState("");
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [mode, setMode] = useState("");
  const [formError, setFormError] = useState<any[]>([]);
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [selectedRowItem, setSelectedRowItem] = useState<{
    [key: string]: any;
  }>({});
  const [dataList, setDataList] = useState<{ [key: string]: any }[]>([]);
  const [initialFormData, setInitialFormData] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [optionData, setOptionData] = useState<any>();
  const [optionList, setOptionList] = useState<any>([]);
  const [isOptionLoaded, setIsOptionLoaded] = useState<boolean>(false);
  const [isAdminForm, setIsAdminForm] = useState<boolean>(false);
  const [incomeCalcValues, setIncomeCalcValues] = useState<any>({});
  const [outgoingCalcValues, setOutgoingCalcValues] = useState<any>({});
  const [reloadRequired, setReloadRequired] = useState(0);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [loading, setLoading] = useState(false);
  const [getConsultant, setgetConsultant] = useState("");

  const [getCommissionType, setgetCommissionType] = useState("");

  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);

  // const getIncomeDataList = () => {
  //   apiServices.income
  //     .getList(props.activeCustomerId)
  //     .then((res) => {
  //       if (res.data !== undefined) {
  //         if (res.data.data) {
  //           let singleData = {};
  //           singleData = {
  //             ...singleData,
  //             income: res.data.data.total,
  //           };
  //           setIncomeCalcValues(singleData);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   if (props.activeCustomerId) {
  //     // getIncomeDataList();
  //     // getOutgoingDataList();
  //   }
  // }, [props.activeCustomerId]);

  // const getOutgoingDataList = () => {
  //   apiServices.outgoings
  //     .getList(props.activeCustomerId)

  //     .then((res) => {
  //       if (res.data !== undefined) {
  //         if (res.data.data) {
  //           let singleData = {};
  //           singleData = {
  //             ...singleData,
  //             outgoings: res.data.data,
  //           };
  //           console.log(singleData, "outgoings---------");

  //           setOutgoingCalcValues(singleData);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // Selection List

  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    // console.log("labelInfo", labelInfo);
    // console.log("fieldInfo", fieldInfo);
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
    // console.log("newlyAddedSelectOptions", newlyAddedSelectOptions);
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {})
        .catch((err) => console.log(err));
    }
  };

  const generateDynamicForm = (formContext: string) => {
    let { initialValues, inputs, validationSchema } = formBuilder({
      context: context,
      formSettings:
        props.module != undefined && props.module == "commission"
          ? objFormSettings.data.commission
          : objFormSettings?.data,
    });

    let filteredSelectionOptions = inputs
      .filter(
        (opt: any) =>
          opt.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.listType) &&
          opt.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.fieldOptionLabel,
        listType: opt.listType,
        formField: opt.field,
      }));
    let filteredPrefixFields = inputs
      .filter(
        (opt: any) =>
          opt.prefix !== undefined &&
          opt.prefix.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.prefix.listType) &&
          opt.prefix.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.prefix.fieldOptionLabel,
        listType: opt.prefix.listType,
        formField: opt.prefix.field,
      }));
    if (filteredPrefixFields && filteredPrefixFields.length > 0) {
      filteredSelectionOptions = [
        ...filteredSelectionOptions,
        ...filteredPrefixFields,
      ];
    }
    // console.log("filteredSelectionOptions===>>>", filteredSelectionOptions);
    // console.log("filteredPrefixFields===>>>", filteredPrefixFields);
    setSelectFieldOptionLabels(filteredSelectionOptions);
    if (filteredSelectionOptions.length > 0) {
      getSelectionListOptions(filteredSelectionOptions);
    }
    setFormFieldData({ initialValues, inputs, validationSchema });
    // setPrimaryContact("home_phone");
  };

  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels
          // .filter((item: any, index: number, self: any) => {
          //   return index === self.findIndex((f: any) => f.label === item.label);
          // })
          .map((itm: any, index: number) => {
            // console.log("index", index);
            if (fieldLabels.length - 1 != index) {
              fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
            } else {
              fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
            }
          });

        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            if (res.data && res.data.data) {
              const filteredOptions = res.data.data.map((item: any) => {
                if (
                  (item.field === "frequency" && context === "CLIENT_INCOME") ||
                  context === "CLIENT_OUTGOINGS"
                ) {
                  item.options = item.options.filter(
                    (option: any) => option.value !== "Single"
                  );
                } else if (
                  item.field === "action_category" &&
                  context === "FACT_FIND_NOTES"
                ) {
                  item.options = item.options.filter((option: any) =>
                    option.label.startsWith("Fact-find")
                  );
                }
                return item;
              });

              // Update the state or variable holding the options
              setSelectFieldOptions(filteredOptions);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // const response = await axios.get("/option2.json");
      // const optiondata = response.data;
      // if (optiondata.data && optiondata.data.length) {
      //   setSelectFieldOptions(optiondata.data);
      // }

      // console.log("optiondata", optiondata);
      // setOptionData(optiondata);
      // optiondata[context] && setOptionList(optiondata[context]);
      // optionList && setIsOptionLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (props.isContactHistory) changeMode("edit");
  }, []);

  useEffect(() => {
    if (reloadRequired !== 0) {
      props.reloadData();
      setReloadRequired(0);
    }
  }, [reloadRequired]);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer?.isFormDataUpdated
  );
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const dispatch: AppDispatch = useDispatch();
  const [currentRecord, setCurrentRecord] = useState(1);
  const [loadStandardActionPopup, setLoadStandardActionPopup] = useState(false);
  const [partnerDialogPopup, setPartnerDialogPopup] = useState(false);
  const [loadStandardActionDate, setLoadStandardActionDate] = useState(
    new Date()
  );
  const [selectedLoadStandardActions, setSelectedLoadStandardActions] =
    useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  const updateConsultantName = (name: any) => {
    setgetConsultant(name);
  };

  const updateCommissionType = (name: any) => {
    setgetCommissionType(name);
  };

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  useEffect(() => {
    if (context !== "") {
      generateDynamicForm(context);
      fetchDataList();
    }
    // const fetchOption = async () => {
    //   try {
    //     const response = await axios.get("/option.json");
    //     const optiondata = response.data;
    //     setOptionData(optiondata);
    //     optiondata[context] && setOptionList(optiondata[context]);
    //     optionList && setIsOptionLoaded(true);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchOption();
    if (props.isFormAdmin) {
      setIsAdminForm(true);
    }
  }, [context]);
  useEffect(() => {
    if (
      mode &&
      selectedRowItem &&
      ["view", "edit"].includes(mode) &&
      Object.keys(selectedRowItem).length !== 0
    ) {
      let rowItem = {};
      if (context === "CLIENT_SERVICE_TYPE") {
        rowItem = {
          ...rowItem,
          // description: selectedRowItem.Description
          //   ? selectedRowItem.Description
          //   : "",
          // start_date: selectedRowItem.StartDate
          //   ? selectedRowItem.StartDate
          //   : "",
          // end_date: selectedRowItem.EndDate ? selectedRowItem.EndDate : "",
          ...selectedRowItem,
        };
      }
      if (context === "COMMISSION_SERVICE_TYPE") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "CLIENT_OBJECTIVES") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          // applicable:
          //   mode === "view"
          //     ? selectedRowItem.applicable === 0
          //       ? "No"
          //       : "Yes"
          //       : selectedRowItem.applicable
        };
      } else if (context === "CLIENT_IDENTITY") {
        rowItem = {
          ...rowItem,
          // category: selectedRowItem.Category ? selectedRowItem.Category : "",
          // item: selectedRowItem.Item ? selectedRowItem.Item : "",
          // reference: selectedRowItem.Reference ? selectedRowItem.Reference : "",
          // issued: selectedRowItem.Issued,
          // expires: selectedRowItem.Expires ? selectedRowItem.Expires : "",
          // issued_at: selectedRowItem.IssuedAt ? selectedRowItem.IssuedAt : "",
          // seen: selectedRowItem.Seen ? selectedRowItem.Seen : "",
          ...selectedRowItem,
        };
      } else if (context === "CLIENT_EMPLOYMENT") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          controlling_director:
            mode === "view"
              ? getYes_NoConversion(selectedRowItem.controlling_director)
              : selectedRowItem.controlling_director,
          death_in_service:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.death_in_service_yn)} ${
                  selectedRowItem.death_in_service
                }`
              : selectedRowItem.death_in_service_yn,
          sick_pay:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.sick_pay_yn)} ${
                  selectedRowItem.sick_pay
                }`
              : selectedRowItem.sick_pay_yn,
          pmi:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.pmi_yn)} ${
                  selectedRowItem.pmi
                }`
              : selectedRowItem.pmi_yn,
          phi:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.phi_yn)}${
                  selectedRowItem.phi
                }`
              : selectedRowItem.phi_yn,
          critical_illness:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.critical_illness_yn)} ${
                  selectedRowItem.critical_illness
                }`
              : selectedRowItem.critical_illness_yn,
          company_car:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.company_car_yn)}${
                  selectedRowItem.company_car
                }`
              : selectedRowItem.company_car_yn,
          share_options:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.share_options_yn)} ${
                  selectedRowItem.share_options
                }`
              : selectedRowItem.share_options_yn,
          other:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.other_yn)} ${
                  selectedRowItem.other
                }`
              : selectedRowItem.other_yn,
          pension:
            mode === "view"
              ? `${getYes_NoConversion(selectedRowItem.pension_yn)} ${
                  selectedRowItem.pension
                }`
              : selectedRowItem.pension_yn,
        };
      } else if (context === "CLIENT_DEPENDANT") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          // date_of_birth:
          //   mode === "edit" ? formatedDate(selectedRowItem.date_of_birth) : null,
        };
      } else if (context === "CLIENT_INCOME") {
        rowItem = {
          ...rowItem,

          ...selectedRowItem,
          owner:
            mode === "view"
              ? getClientType(selectedRowItem.owner)
              : selectedRowItem.owner,
        };
      } else if (context === "CONTACTS_NOTES") {
        rowItem = {
          ...rowItem,

          ...selectedRowItem,
          note_time:
            mode === "view"
              ? formatedTime(selectedRowItem.note_time)
              : selectedRowItem.note_time,
          category:
            mode === "view"
              ? getClientNoteCategories(selectedRowItem.category)
              : selectedRowItem.category,
        };
      } else if (context === "FACT_FIND_NOTES") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          note_time:
            mode === "view"
              ? formatedTime(selectedRowItem.note_time)
              : selectedRowItem.note_time,
          category:
            mode === "view"
              ? getClientNoteCategories(selectedRowItem.category)
              : selectedRowItem.category,
        };
      } else if (context === "CONTACTS_TIME_ALLOCATION") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          duration:
            mode === "view"
              ? formatedTime(selectedRowItem.duration)
              : selectedRowItem.duration,
        };
      } else if (context === "CONTACTS_CLIENT_ACTION") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          appointment_time:
            mode === "view"
              ? formatedTime(selectedRowItem.appointment_time)
              : selectedRowItem.appointment_time,
        };
      } else {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      }

      setInitialFormData(rowItem);
    }
  }, [mode, selectedRowItem]);

  const removeFile = () => {
    setUploadedFiles([]);
  };

  const addLoadStandardActions = () => {
    setLoadStandardActionPopup(false);
    if (selectedLoadStandardActions.length > 0) {
      if (formSettingsObj && formSettingsObj.data) {
        let configObj: any = checkIfKeyExist(
          formSettingsObj.data.formConfig,
          context
        );

        if (
          configObj &&
          configObj.renderForm &&
          configObj.renderForm?.reference_id
        ) {
          let actReq: any = {};
          actReq[configObj.renderForm?.reference_id] = {
            customer_id: props.activeCustomerId,
            effective_date: formatedDate2(loadStandardActionDate),
            tracking_client_actions: [],
          };
          let selAct: any = [];
          selectedLoadStandardActions.map((v: any) => {
            selAct.push({
              tracking_id: v,
            });
          });
          actReq[configObj.renderForm?.reference_id][
            "tracking_client_actions"
          ] = selAct;

          apiServices.tackingActions
            .addclientAction(actReq)
            .then((response) => {
              if (response.data && response.data.data) {
                setSelectedLoadStandardActions([]);
                setLoadStandardActionPopup(false);
                setLoadStandardActionDate(new Date());
                props.getDataList();
              }

              // console.log("response---traking", response);
            })
            .catch((err) => console.log(err));
        }
      }
    }
  };
  const loadActionSelected = (action: any) => {
    // console.log("action", action);
    let act: any = [...selectedLoadStandardActions];

    const index = act.indexOf(action);
    if (index !== -1) {
      act.splice(index, 1);
    } else {
      act.push(action);
    }
    // console.log("actionSelected==action", action);
    // setSelectedActions(act);
    setSelectedLoadStandardActions(act);
  };
  // const generateDynamicForm = (formContext: string) => {
  //   console.log("formContext", formContext);
  //   console.log("objFormSettings.data", objFormSettings.data);
  //   let { initialValues, inputs, validationSchema } = formBuilder({
  //     context: context,
  //     formSettings: objFormSettings.data,
  //   });
  //   console.log("initialValues", initialValues);
  //   console.log("inputs", inputs);
  //   console.log("validationSchema", validationSchema);
  //   console.log("formContext", formContext);
  //   setFormFieldData({ initialValues, inputs, validationSchema });
  // };
  const formik = useFormik({
    initialValues:
      mode && ["edit", "view"].includes(mode)
        ? initialFormData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},
    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,

    onSubmit: function (values, { setSubmitting }) {
      // formik.resetForm();

      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
        setTimeout(() => {
          if (props.isContactHistory) {
            dispatch(setDialogModalOptions({ open: false }));
          }
        }, 3000);
      }
    },
  });
  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  useEffect(() => {
    fetchDataList();
  }, [props.initialData, props.attitudeToRiskData]);
  const formSubmitted = (values: any) => {
    // console.log("formFieldData in ", formFieldData);
    // console.log("formFieldData in values ", values);
    setIsFormLoading(true);
    let payload = formFieldData.inputs.map(({ field }: any) => field);
    let mappedFields = formFieldData.inputs
      .map((f: any) => {
        if (f.prefix !== undefined && f.prefix.field !== undefined) {
          return f.prefix.field;
        } else {
          return null;
        }
      })
      .filter((item: any) => item !== null);
    if (mappedFields && mappedFields.length > 0) {
      payload = [...payload, ...mappedFields];
    }
    // console.log("payload in ", payload);
    setFormError([]);
    let reqBody: { [key: string]: any } = {};

    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
      if (reqBody.consultant !== undefined) {
        setgetConsultant(reqBody.consultant);
      }
      if (reqBody.commission_type !== undefined) {
        setgetCommissionType(reqBody.commission_type);
      }
    }

    if (mode === "add") {
      setFormError([]);
      let addReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "objectives") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            objective_description: reqBody.objective_description,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            applicable: reqBody.applicable === "" ? 0 : reqBody.applicable,
            comments: reqBody.comments,
            rating: reqBody.rating === "" ? 0 : reqBody.rating,
          };
        } else if (props.config.renderForm.reference_id === "identity") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            category: reqBody.category,
            item: reqBody.item,
            reference: reqBody.reference,
            issued: reqBody.issued,
            expires: reqBody.expires,
            issued_at: reqBody.issued_at,
            seen: reqBody.seen,
          };
        } else if (props.config.renderForm.reference_id === "service_type") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            description: reqBody.description,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date ? reqBody.end_date : "",
          };
        } else if (props.config.renderForm.reference_id === "employment") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            principal_employment: reqBody.principal_employment,
            occupation: reqBody.occupation,
            employment_type: reqBody.employment_type,
            employer_name: reqBody.employer_name,
            address_1: reqBody.address_1,
            address_2: reqBody.address_2,
            address_3: reqBody.address_3,
            town: reqBody.town,
            county: reqBody.county,
            country: reqBody.country,
            postcode: reqBody.postcode,
            phone: reqBody.phone,
            fax: reqBody.fax,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date,
            salary_review_date: reqBody.salary_review_date,
            payroll_id: reqBody.payroll_id,
            position: reqBody.position,
            controlling_director: reqBody.controlling_director,
            shareholding: reqBody.shareholding,
            note_text: reqBody.note_text,
            employment_basis: reqBody.employment_basis,
            retirement_age:
              reqBody.retirement_age === "" ? 0 : reqBody.retirement_age,
            death_in_service_yn: parseInt(reqBody.death_in_service_yn),
            death_in_service: reqBody.death_in_service,
            sick_pay_yn: parseInt(reqBody.sick_pay_yn),
            sick_pay: reqBody.sick_pay,
            pmi_yn: parseInt(reqBody.pmi_yn),
            pmi: reqBody.pmi,
            phi_yn: parseInt(reqBody.phi_yn),
            phi: reqBody.phi,
            critical_illness_yn: parseInt(reqBody.critical_illness_yn),
            critical_illness: reqBody.critical_illness,
            company_car_yn: parseInt(reqBody.company_car_yn),
            company_car: reqBody.company_car,
            share_options_yn: parseInt(reqBody.share_options_yn),
            share_options: reqBody.share_options,
            other_yn: parseInt(reqBody.other_yn),
            other: reqBody.other,
            pension_yn: parseInt(reqBody.pension_yn),
            pension: reqBody.pension,
            auto_enrolment: reqBody.auto_enrolment,
            // cash_calc_id: reqBody.createdAt,
          };
        } else if (props.config.renderForm.reference_id === "dependants") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            title: reqBody.title,
            first_names: reqBody.first_names,
            last_name: reqBody.last_name ? reqBody.last_name : "",
            known_as: reqBody.known_as ? reqBody.known_as : "",
            sex: reqBody.sex ? reqBody.sex : "",
            date_of_birth: reqBody.date_of_birth ? reqBody.date_of_birth : "",
            relation: reqBody.relation ? reqBody.relation : "",
            parent: reqBody.parent ? reqBody.parent : "",
            smoker: reqBody.smoker ? reqBody.smoker : "",
            living_at_home: reqBody.living_at_home
              ? reqBody.living_at_home
              : "",
            financially_dependant: reqBody.financially_dependant
              ? reqBody.financially_dependant
              : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
          };
        } else if (props.config.renderForm.reference_id === "income") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id:
              formik.values.owner === "1"
                ? props.activePartnerId
                : props.activeCustomerId,
            owner: parseInt(reqBody.owner),
            income_type: reqBody.income_type,
            gross_amount:
              reqBody.gross_amount !== undefined && reqBody.gross_amount !== ""
                ? parseFloat(reqBody.gross_amount)
                : 0,
            net_amount:
              reqBody.net_amount !== undefined && reqBody.net_amount !== ""
                ? parseFloat(reqBody.net_amount)
                : 0,
            frequency: reqBody.frequency ? reqBody.frequency : "",
            source: reqBody.source ? reqBody.source : "",
            start_date: reqBody.start_date ? reqBody.start_date : "",
            end_date: reqBody.end_date ? reqBody.end_date : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
          };
        } else if (props.config.renderForm.reference_id === "outgoings") {
          addReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            description: reqBody.description,
            frequency: reqBody.frequency,
            client_amount:
              reqBody.client_amount !== undefined &&
              reqBody.client_amount !== ""
                ? parseFloat(reqBody.client_amount)
                : 0,
            partner_amount:
              reqBody.partner_amount !== undefined &&
              reqBody.partner_amount !== ""
                ? parseFloat(reqBody.partner_amount)
                : 0,
            joint_amount:
              reqBody.joint_amount !== undefined && reqBody.joint_amount !== ""
                ? parseFloat(reqBody.joint_amount)
                : 0,
            sort_order:
              reqBody.sort_order !== undefined && reqBody.sort_order !== ""
                ? parseInt(reqBody.sort_order)
                : 0,
            // sort_order: reqBody.sort_order ? reqBody.sort_order : 0,
            discretionary: reqBody.discretionary ? reqBody.discretionary : "",
          };
        } else if (props.config.renderForm.reference_id === "address_book") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };

          // addReq[props.config.renderForm.reference_id]={
          //   "customer_id":props.activeCustomerId,
          //   "description":reqBody.description,
          //   "frequency":reqBody.frequency,
          //   "client_amount":reqBody.client_amount?reqBody.client_amount:0,
          //   "partner_amount":reqBody.partner_amount?reqBody.partner_amount:0,
          //   "joint_amount":reqBody.joint_amount?reqBody.joint_amount:0,
          //   "sort_order":reqBody.sort_order?reqBody.sort_order:0,
          // }
        } else if (props.config.renderForm.reference_id === "contacts_notes") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            category: parseInt(reqBody.category),
          };

          // addReq[props.config.renderForm.reference_id]={
          //   "customer_id":props.activeCustomerId,
          //   "description":reqBody.description,
          //   "frequency":reqBody.frequency,
          //   "client_amount":reqBody.client_amount?reqBody.client_amount:0,
          //   "partner_amount":reqBody.partner_amount?reqBody.partner_amount:0,
          //   "joint_amount":reqBody.joint_amount?reqBody.joint_amount:0,
          //   "sort_order":reqBody.sort_order?reqBody.sort_order:0,
          // }
        } else if (props.config.renderForm.reference_id === "fact_find_notes") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            category: parseInt(reqBody.category),
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_time_allocation"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_client_action"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            score: 0,
          };
        } else if (props.config.renderForm.reference_id === "fees_charges") {
          addReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            amount_to_company: parseFloat(reqBody.amount_to_company),

            commission_type:
              getCommissionType === ""
                ? reqBody.commission_type
                : getCommissionType,
          };
          addReq[props.config.renderForm.reference_id]["consultant"] =
            reqBody.consultant_id === "" ? "" : getConsultant;

          addReq[props.config.renderForm.reference_id]["commission_type"] =
            getCommissionType === ""
              ? reqBody.commission_type
              : getCommissionType;
        } else {
          addReq[props.config.renderForm.reference_id] = reqBody;
        }
      }

      if (
        [
          "CLIENT_DEPENDANT",
          "CLIENT_INCOME",
          "CLIENT_OUTGOINGS",
          "ADDRESS_BOOK",
          "CONTACTS_NOTES",
          "FACT_FIND_NOTES",
          "CONTACTS_TIME_ALLOCATION",
          "CONTACTS_CLIENT_ACTION",
          "CLIENT_ATTITUDE_TO_RISK",
          "FEES_CHARGES",
        ].includes(props.context)
      ) {
        if (props.context === "CLIENT_DEPENDANT") {
          apiServices.dependant
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
              props.reloadData();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CLIENT_INCOME") {
          apiServices.income
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
              props.reloadData();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CLIENT_OUTGOINGS") {
          apiServices.outgoings
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "ADDRESS_BOOK") {
          apiServices.addressBook
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CONTACTS_NOTES") {
          const formData = new FormData();
          const {
            note_date,
            note_time,
            note_type,
            note_text,
            category,
            file_link,
            customer_id,
          } = addReq[Object.keys(addReq)[0]];

          formData.append("context", Object.keys(addReq)[0]);
          formData.append("note_date", note_date);
          formData.append("note_time", note_time);
          formData.append("category", category);
          formData.append("note_text", note_text);
          formData.append("note_type", note_type);
          formData.append("customer_id", customer_id);
          formData.append("file", file_link);
          formData.append("visible", "public");
          apiServices.notes
            .create(formData)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
              formik.resetForm();
              removeFile();
            })
            .catch((e: Error) => {
              console.log(e);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "FACT_FIND_NOTES") {
          const formData = new FormData();
          const {
            note_date,
            note_time,
            note_type,
            note_text,
            category,
            file_link,
            customer_id,
          } = addReq[Object.keys(addReq)[0]];
          formData.append("context", Object.keys(addReq)[0]);
          formData.append("note_date", note_date);
          formData.append("note_time", note_time);
          formData.append("category", category);
          formData.append("note_text", note_text);
          formData.append("note_type", note_type);
          formData.append("customer_id", customer_id);
          formData.append("file", file_link);
          formData.append("visible", "public");
          apiServices.factFindNote
            .createNew(formData)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
              formik.resetForm();
              removeFile();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CONTACTS_CLIENT_ACTION") {
          apiServices.contactClientAction
            .createNew(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CONTACTS_TIME_ALLOCATION") {
          apiServices.timeAllocation
            .create(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "CLIENT_ATTITUDE_TO_RISK") {
          apiServices.attitudeToRisk
            .create(addReq)
            .then((response: any) => {
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
            });
        } else if (props.context === "FEES_CHARGES") {
          setLoading(true);
          apiServices.feesCharges
            .create(addReq)
            .then((response: any) => {
              setLoading(false);
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              setLoading(false);
              handleApiErrorResponse(e);
            });
        } else {
        }
      } else {
        apiServices.client
          .createNew(addReq)
          .then((response: any) => {
            AddSelectOptions();
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      }
    } else if (mode === "edit") {
      setFormError([]);
      let updateReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "objectives") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            objective_description: reqBody.objective_description,
            sort_order:
              reqBody.sort_order === "" ? 0 : parseInt(reqBody.sort_order),
            comments: reqBody.comments,
            rating: reqBody.rating === "" ? 0 : parseInt(reqBody.rating),
            applicable:
              reqBody.applicable === "" ? 0 : parseInt(reqBody.applicable),
          };
        } else if (props.config.renderForm.reference_id === "service_type") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            description: reqBody.description,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date ? reqBody.end_date : "",
          };
        } else if (props.config.renderForm.reference_id === "identity") {
          updateReq[props.config.renderForm.reference_id] = {
            // Customer_id: props.activeCustomerId,
            category: reqBody.category,
            item: reqBody.item,
            reference: reqBody.reference,
            issued: reqBody.issued,
            expires: reqBody.expires,
            issued_at: reqBody.issued_at,
            seen: reqBody.seen,
          };
        } else if (props.config.renderForm.reference_id === "employment") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            principal_employment: reqBody.principal_employment,
            occupation: reqBody.occupation,
            employment_type: reqBody.employment_type,
            employer_name: reqBody.employer_name,
            address_1: reqBody.address_1,
            address_2: reqBody.address_2,
            address_3: reqBody.address_3,
            town: reqBody.town,
            county: reqBody.county,
            country: reqBody.country,
            postcode: reqBody.postcode,
            phone: reqBody.phone,
            fax: reqBody.fax,
            start_date: reqBody.start_date,
            end_date: reqBody.end_date,
            salary_review_date: reqBody.salary_review_date,
            payroll_id: reqBody.payroll_id,
            position: reqBody.position,
            controlling_director: reqBody.controlling_director,
            shareholding: reqBody.shareholding,
            note_text: reqBody.note_text,
            employment_basis: reqBody.employment_basis,
            retirement_age:
              reqBody.retirement_age === "" ? 0 : reqBody.retirement_age,
            death_in_service_yn: parseInt(reqBody.death_in_service_yn),
            death_in_service: reqBody.death_in_service,
            sick_pay_yn: parseInt(reqBody.sick_pay_yn),
            sick_pay: reqBody.sick_pay,
            pmi_yn: parseInt(reqBody.pmi_yn),
            pmi: reqBody.pmi,
            phi_yn: parseInt(reqBody.phi_yn),
            phi: reqBody.phi,
            critical_illness_yn: parseInt(reqBody.critical_illness_yn),
            critical_illness: reqBody.critical_illness,
            company_car_yn: parseInt(reqBody.company_car_yn),
            company_car: reqBody.company_car,
            share_options_yn: parseInt(reqBody.share_options_yn),
            share_options: reqBody.share_options,
            other_yn: parseInt(reqBody.other_yn),
            other: reqBody.other,
            pension_yn: parseInt(reqBody.pension_yn),
            pension: reqBody.pension,
            auto_enrolment: reqBody.auto_enrolment,
            // cash_calc_id: reqBody.createdAt,
          };
        } else if (props.config.renderForm.reference_id === "dependants") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            title: reqBody.title,
            first_names: reqBody.first_names,
            last_name: reqBody.last_name ? reqBody.last_name : "",
            known_as: reqBody.known_as ? reqBody.known_as : "",
            sex: reqBody.sex ? reqBody.sex : "",
            date_of_birth: formatedDate2(reqBody.date_of_birth),
            relation: reqBody.relation ? reqBody.relation : "",
            parent: reqBody.parent ? reqBody.parent : "",
            smoker: reqBody.smoker ? reqBody.smoker : "",
            living_at_home: reqBody.living_at_home
              ? reqBody.living_at_home
              : "",
            financially_dependant: reqBody.financially_dependant
              ? reqBody.financially_dependant
              : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
          };
        } else if (props.config.renderForm.reference_id === "income") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id:
              formik.values.owner === "1"
                ? props.activePartnerId
                : props.activeCustomerId,
            owner: parseInt(reqBody.owner),
            income_type: reqBody.income_type,
            gross_amount:
              reqBody.gross_amount !== undefined && reqBody.gross_amount !== ""
                ? parseFloat(reqBody.gross_amount)
                : 0,
            net_amount:
              reqBody.net_amount !== undefined && reqBody.net_amount !== ""
                ? parseFloat(reqBody.net_amount)
                : 0,
            frequency: reqBody.frequency ? reqBody.frequency : "",
            source: reqBody.source ? reqBody.source : "",
            start_date: reqBody.start_date ? reqBody.start_date : "",
            end_date: reqBody.end_date ? reqBody.end_date : "",
            note_text: reqBody.note_text ? reqBody.note_text : "",
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
          };
        } else if (props.config.renderForm.reference_id === "outgoings") {
          updateReq[props.config.renderForm.reference_id] = {
            customer_id: props.activeCustomerId,
            description: reqBody.description,
            frequency: reqBody.frequency,
            client_amount:
              reqBody.client_amount !== undefined &&
              reqBody.client_amount !== ""
                ? parseFloat(reqBody.client_amount)
                : 0,
            partner_amount:
              reqBody.partner_amount !== undefined &&
              reqBody.partner_amount !== ""
                ? parseFloat(reqBody.partner_amount)
                : 0,
            joint_amount:
              reqBody.joint_amount !== undefined && reqBody.joint_amount !== ""
                ? parseFloat(reqBody.joint_amount)
                : 0,
            sort_order:
              reqBody.sort_order !== undefined && reqBody.sort_order !== ""
                ? parseInt(reqBody.sort_order)
                : 0,
            discretionary: reqBody.discretionary ? reqBody.discretionary : "",
          };
        } else if (props.config.renderForm.reference_id === "address_book") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };
        } else if (props.config.renderForm.reference_id === "contacts_notes") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            category: parseInt(reqBody.category),
          };
        } else if (props.config.renderForm.reference_id === "fact_find_notes") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            category: parseInt(reqBody.category),
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_client_action"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };
        } else if (
          props.config.renderForm.reference_id === "contacts_time_allocation"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            category: parseInt(reqBody.category),
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
          };
        } else if (props.config.renderForm.reference_id === "fees_charges") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            customer_id: props.activeCustomerId,
            amount_to_company: parseFloat(reqBody.amount_to_company),
          };
          updateReq[props.config.renderForm.reference_id]["consultant"] =
            reqBody.consultant_id === "" ? "" : getConsultant;
          updateReq[props.config.renderForm.reference_id]["commission_type"] =
            getCommissionType === ""
              ? reqBody.commission_type
              : getCommissionType;
        } else {
          updateReq[props.config.renderForm.reference_id] = reqBody;
        }
      }
      if (props.config.renderForm.reference_id === "service_type") {
        apiServices.client
          .updateServiceType(
            props.activeCustomerId,
            selectedRowItem.servicetype_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
            // props.notify(e.message, "", "error");
          });
      } else if (props.config.renderForm.reference_id === "objectives") {
        apiServices.client
          .updateObjectives(
            props.activeCustomerId,
            selectedRowItem.objective_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.messaAddSelectOptions();ge, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   // handleRowAction("view",selectedRowItem);
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            // props.notify(e.message, "", "error");
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "identity") {
        apiServices.client
          .updateIdentity(
            props.activeCustomerId,
            selectedRowItem.identity_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
            // props.notify(e.message, "", "error");
          });
      } else if (props.config.renderForm.reference_id === "employment") {
        apiServices.client
          .updateEmployment(
            props.activeCustomerId,
            selectedRowItem.employment_id,
            updateReq
          )

          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            // if (!response.data.isError) {
            //   props.notify(response.data.message, "", "success");
            //   // props.setFormUpdated(false);
            //   dispatch(resetFormData());

            //   props.reloadDetail();
            //   //  props.getList();
            //   //  if (props.resetClientFormSubmited) {
            //   //    props.resetClientFormSubmited();
            //   //  }
            //   //  if (props.changeMode) {
            //   //    // props.setActiveItem(response.data.data);
            //   //    props.changeMode("edit");
            //   //  }
            //   if (nextAction === "next") {
            //     props.changeTab?.(props.tabMenuIndex.next);
            //   }
            // } else if (response.data.isError) {
            //   if (
            //     response.data.error &&
            //     response.data.error &&
            //     Object.keys(response.data.error).length !== 0
            //   ) {
            //     if (Array.isArray(response.data.error.message)) {
            //       setFormError(response.data.error.message);
            //     } else {
            //       let errMsg = [];
            //       errMsg.push(response.data.error.description);
            //       setFormError(errMsg);
            //     }
            //   }
            // } else {
            //   props.notify("Something went wrong", "", "error");
            // }
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
            // props.notify(e.message, "", "error");
          });
      } else if (props.config.renderForm.reference_id === "dependants") {
        apiServices.dependant
          .updateDependant(
            props.activeCustomerId,
            selectedRowItem.dependant_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "income") {
        apiServices.income
          .updateIncome(selectedRowItem.income_id, updateReq)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "outgoings") {
        apiServices.outgoings
          .updateOutgoings(selectedRowItem.expense_id, updateReq)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "address_book") {
        apiServices.addressBook
          .updateAddressBook(
            props.activeCustomerId,
            selectedRowItem.address_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "contacts_notes") {
        const {
          note_date,
          note_time,
          note_type,
          note_text,
          category,
          file_link,
          customer_id,
        } = updateReq[Object.keys(updateReq)[0]];
        const formData = new FormData();

        formData.append("context", Object.keys(updateReq)[0]);
        formData.append("note_date", note_date);
        formData.append("note_time", note_time);
        formData.append("category", category);
        formData.append("note_text", note_text);
        formData.append("note_type", note_type);
        formData.append("customer_id", customer_id);
        formData.append("file", file_link);
        formData.append("visible", "public");

        apiServices.notes
          .updateNotes(selectedRowItem.note_id, formData)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            removeFile();
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "fact_find_notes") {
        const {
          note_date,
          note_time,
          note_type,
          note_text,
          category,
          file_link,
          customer_id,
        } = updateReq[Object.keys(updateReq)[0]];
        const formData = new FormData();

        formData.append("context", Object.keys(updateReq)[0]);
        formData.append("note_date", note_date);
        formData.append("note_time", note_time);
        formData.append("category", category);
        formData.append("note_text", note_text);
        formData.append("note_type", note_type);
        formData.append("customer_id", customer_id);
        formData.append("file", file_link);
        formData.append("visible", "public");

        apiServices.factFindNote
          .updateFactFindNote(selectedRowItem.note_id, formData)
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
            removeFile();
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "contacts_client_action"
      ) {
        apiServices.contactClientAction
          .updateFactFindNote(
            props.activeCustomerId,
            selectedRowItem.appointment_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "contacts_time_allocation"
      ) {
        apiServices.timeAllocation
          .update(
            props.activeCustomerId,
            selectedRowItem.timeallocation_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "attitude_to_risk") {
        apiServices.attitudeToRisk
          .update(
            props.activeCustomerId,
            selectedRowItem.attituderisk_id,
            updateReq
          )
          .then((response: any) => {
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "fees_charges") {
        setLoading(true);
        apiServices.feesCharges
          .update(
            props.activeCustomerId,
            selectedRowItem.commission_id,
            updateReq
          )
          .then((response: any) => {
            setLoading(false);
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            setLoading(false);
            handleApiErrorResponse(e);
          });
      } else {
      }
    }
  };
  const handleApiSuccessResponse = (response: any) => {
    if (!response.data.isError) {
      setTimeout(() => {
        setIsFormLoading(false);
        props.notify(response.data.message, "", "success");
      }, 1000);
      dispatch(resetFormData());

      props.reloadData();

      if (nextAction === "next") {
        props.changeTab?.(props.tabMenuIndex.next);
      }
    } else if (response.data.isError) {
      if (
        response.data.error &&
        response.data.error &&
        Object.keys(response.data.error).length !== 0
      ) {
        if (Array.isArray(response.data.error.message)) {
          setFormError(response.data.error.message);
        } else {
          setFormError((prev) => [response.data.error.message]);
        }
      }
    } else {
      props.notify("Something went wrong", "", "error");
    }
  };
  const handleApiErrorResponse = (e: any) => {
    props.notify(e.message, "", "error");
  };
  const customHandleChange = (event: any) => {
    // alert("custom handle chnage");
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;
    // dispatch(setFormData());
    // if (["title", "first_name", "last_name"].includes(event.target.name)) {
    //   formik.setFieldValue("salutation", getSalutations(data));
    // }
    updatePresent(data);
    // if (isFormDataUpdated) {
    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      updated = false;
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
      updated = true;
    }
    // }

    if (mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(mode === "add" ? "New" : "");
    }
  };
  const changeMode = (mode: string) => {
    setMode(mode);
  };
  const handleRowAction = (action: string, row: any) => {
    changeMode(action);
    setSelectedRowItem(row);
  };
  const fetchDataList = () => {
    if (context === "CLIENT_OBJECTIVES" && props.initialData.objectives) {
      setDataList(
        props.initialData.objectives ? props.initialData.objectives : []
      );
      if (props.initialData.objectives.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.objectives.findIndex(
            (x: any) => x.objective_id === selectedRowItem.objective_id
          );

          handleRowAction("view", props.initialData.objectives[index]);
        } else {
          handleRowAction("view", props.initialData.objectives[0]);
        }
      }
    } else if (context === "CLIENT_EMPLOYMENT") {
      setDataList(
        props.initialData.employment ? props.initialData.employment : []
      );
      if (props.initialData?.employment?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.employment.findIndex(
            (x: any) => x.employment_id === selectedRowItem.employment_id
          );
          handleRowAction("view", props.initialData.employment[index]);
        } else {
          handleRowAction("view", props.initialData.employment[0]);
        }
      }
    } else if (context === "CLIENT_IDENTITY") {
      setDataList(props.initialData.identity ? props.initialData.identity : []);
      if (props.initialData?.identity?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.identity.findIndex(
            (x: any) => x.identity_id === selectedRowItem.identity_id
          );
          handleRowAction("view", props.initialData.identity[index]);
        } else {
          handleRowAction("view", props.initialData.identity[0]);
        }
      }
    } else if (context === "CLIENT_SERVICE_TYPE") {
      setDataList(
        props.initialData.servicetype ? props.initialData.servicetype : []
      );
      if (props.initialData?.servicetype?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.servicetype.findIndex(
            (x: any) => x.servicetype_id === selectedRowItem.servicetype_id
          );
          handleRowAction("view", props.initialData.servicetype[index]);
        } else {
          handleRowAction("view", props.initialData.servicetype[0]);
        }
      }
    } else if (context === "COMMISSION_SERVICE_TYPE") {
      setDataList(
        props.initialData.servicetype ? props.initialData.servicetype : []
      );
      if (props.initialData?.servicetype?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.servicetype.findIndex(
            (x: any) => x.servicetype_id === selectedRowItem.servicetype_id
          );
          handleRowAction("view", props.initialData.servicetype[index]);
        } else {
          handleRowAction("view", props.initialData.servicetype[0]);
        }
      }
    } else if (context === "CLIENT_DEPENDANT") {
      setDataList(
        props.initialData.dependants ? props.initialData.dependants : []
      );
      if (props.initialData?.dependants?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.dependants.findIndex(
            (x: any) => x.dependant_id === selectedRowItem.dependant_id
          );
          handleRowAction("view", props.initialData.dependants[index]);
        } else {
          handleRowAction("view", props.initialData.dependants[0]);
        }
      }
    } else if (context === "CLIENT_INCOME") {
      setDataList(props.initialData.income ? props.initialData.income : []);
      setIncomeCalcValues(
        props.initialData.incomeTotals ? props.initialData.incomeTotals : {}
      );
      if (props.initialData?.income?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.income.findIndex(
            (x: any) => x.income_id === selectedRowItem.income_id
          );
          handleRowAction("view", props.initialData.income[index]);
        } else {
          handleRowAction("view", props.initialData.income[0]);
        }
      }
    } else if (context === "CLIENT_OUTGOINGS") {
      setDataList(
        props.initialData.outgoings ? props.initialData.outgoings : []
      );
      setOutgoingCalcValues(
        props.initialData.outgoingsTotals
          ? props.initialData.outgoingsTotals
          : {}
      );
      if (props.initialData?.outgoings?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.outgoings.findIndex(
            (x: any) => x.expense_id === selectedRowItem.expense_id
          );
          handleRowAction("view", props.initialData.outgoings[index]);
        } else {
          handleRowAction("view", props.initialData.outgoings[0]);
        }
      }
    } else if (context === "CLIENT_HISTORY") {
      setDataList(
        props.initialData.clientHistory ? props.initialData.clientHistory : []
      );

      if (props.initialData?.clientHistory?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.clientHistory.findIndex(
            (x: any) => x.address_id === selectedRowItem.address_id
          );
          handleRowAction("view", props.initialData.clientHistory[index]);
        } else {
          handleRowAction("view", props.initialData.clientHistory[0]);
        }
      }
    } else if (context === "ADDRESS_BOOK") {
      setDataList(
        props.initialData.addressBook ? props.initialData.addressBook : []
      );

      if (props.initialData?.addressBook?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.addressBook.findIndex(
            (x: any) => x.address_id === selectedRowItem.address_id
          );
          handleRowAction("view", props.initialData.addressBook[index]);
        } else {
          handleRowAction("view", props.initialData.addressBook[0]);
        }
      }
    } else if (context === "FACT_FIND_NOTES") {
      setDataList(
        props.initialData.factFindNote ? props.initialData.factFindNote : []
      );
      if (props.initialData?.factFindNote?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.factFindNote.findIndex(
            (x: any) => x.note_id === selectedRowItem.note_id
          );
          handleRowAction("view", props.initialData.factFindNote[index]);
        } else {
          handleRowAction("view", props.initialData.factFindNote[0]);
        }
      }
    } else if (context === "CONTACTS_TIME_ALLOCATION") {
      setDataList(
        props.initialData.timeAllocation ? props.initialData.timeAllocation : []
      );
      if (props.initialData?.timeAllocation?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index: any = props.initialData.timeAllocation.findIndex(
            (x: any) =>
              x.timeAllocation_id === selectedRowItem.timeAllocation_id
          );
          handleRowAction("view", props.initialData.timeAllocation[index]);
        } else {
          handleRowAction("view", props.initialData.timeAllocation[0]);
        }
      }
    } else if (context === "CONTACTS_CLIENT_ACTION") {
      setDataList(
        props.initialData.contactClientAction
          ? props.initialData.contactClientAction
          : []
      );
      if (props.initialData?.contactClientAction?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index: any = props.initialData.contactClientAction.findIndex(
            (x: any) =>
              x.contactClientAction_id ===
              selectedRowItem.contactClientAction_id
          );
          handleRowAction("view", props.initialData.contactClientAction[index]);
        } else {
          handleRowAction("view", props.initialData.contactClientAction[0]);
        }
      }
    } else if (context === "CONTACTS_NOTES") {
      setDataList(props.initialData?.notes ? props.initialData?.notes : []);
      if (props.initialData?.notes?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.notes.findIndex(
            (x: any) => x.note_id === selectedRowItem.note_id
          );
          handleRowAction("view", props.initialData.notes[index]);
        } else {
          handleRowAction("view", props.initialData.notes[0]);
        }
      }
    } else if (context === "CLIENT_ATTITUDE_TO_RISK") {
      setDataList(props.attitudeToRiskData ? props.attitudeToRiskData : []);
      if (props.attitudeToRiskData?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.attitudeToRiskData.findIndex(
            (x: any) => x.attituderisk_id === selectedRowItem.attituderisk_id
          );
          handleRowAction("view", props.attitudeToRiskData[index]);
        } else {
          handleRowAction("view", props.attitudeToRiskData[0]);
        }
      }
    } else if (context === "FEES_CHARGES") {
      setDataList(
        props.initialData.feesCharges ? props.initialData.feesCharges : []
      );
      if (props.initialData?.feesCharges?.length > 0) {
        if (selectedRowItem && Object.keys(selectedRowItem).length !== 0) {
          let index = props.initialData.feesCharges.findIndex(
            (x: any) => x.commission_id === selectedRowItem.commission_id
          );
          handleRowAction("view", props.initialData.feesCharges[index]);
        } else {
          handleRowAction("view", props.initialData.feesCharges[0]);
        }
      }
    } else {
      setDataList([]);
    }
  };
  return (
    <>
      <OverlayLoader isLoading={loading} />
      {props.title !== undefined && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Label size="large">{props.title}</Label>
        </div>
      )}
      {mode && mode === "view" && (
        <div
          className={`${
            context === "CLIENT_INCOME" || context === "CLIENT_OUTGOINGS"
              ? "table-wrapper-in-container"
              : ""
          }`}
        >
          {context === "CLIENT_INCOME" && (
            <IncomeCalcTable IncomeCalculationValues={incomeCalcValues} />
          )}
          {context === "CLIENT_OUTGOINGS" && (
            <OutgoingCalcTable OutgoingCalculationValues={outgoingCalcValues} />
          )}
        </div>
      )}

      {dataList && dataList.length === 0 && mode === "" && (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          {context === "CLIENT_ATTITUDE_TO_RISK" &&
          checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) &&
          !convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
            "client_attitude_to_risk_get_default"
          ) ? (
            <Button
              appearance="secondary"
              shape="square"
              disabled={
                !convertStringToArray(
                  process.env.REACT_APP_DISABLE_CTA_BUTTON
                ).includes("client_attitude_get_default")
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    master_table: true,
                    customerId: props.activeCustomerId,
                    table_type: "attitudeToRisk",
                    reload: () => {
                      setReloadRequired(reloadRequired + 1);
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Close",
                    },
                    apply: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Apply",
                      type: "primary",
                    },
                  })
                );
              }}
            >
              Get Default Categories
            </Button>
          ) : (
            ""
          )}

          {context === "CLIENT_OBJECTIVES" &&
          checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) &&
          !convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
            "client_objectives_get_default"
          ) ? (
            <Button
              appearance="secondary"
              shape="square"
              disabled={
                !convertStringToArray(
                  process.env.REACT_APP_DISABLE_CTA_BUTTON
                ).includes("client_objectives_disable_get_default")
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    master_table: true,
                    table_type: "objectives",
                    customerId: props.activeCustomerId,
                    reload: () => {
                      setReloadRequired(reloadRequired + 1);
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Close",
                    },
                    apply: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Apply",
                      type: "primary",
                    },
                  })
                );
              }}
            >
              Get Default Objectives
            </Button>
          ) : (
            ""
          )}

          {context === "CLIENT_INCOME" &&
          checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) &&
          !convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
            "client_income_categories"
          ) ? (
            <Button
              appearance="secondary"
              shape="square"
              disabled={
                !convertStringToArray(
                  process.env.REACT_APP_DISABLE_CTA_BUTTON
                ).includes("client_income_get_default")
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    master_table: true,
                    customerId: props.activeCustomerId,
                    table_type: "income",
                    reload: () => {
                      setReloadRequired(reloadRequired + 1);
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Close",
                    },
                    apply: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Apply",
                      type: "primary",
                    },
                  })
                );
              }}
            >
              Get Default Income Categories
            </Button>
          ) : (
            ""
          )}
          {context === "CLIENT_OUTGOINGS" &&
          checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) &&
          !convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
            "client_outgoings_get_default"
          ) ? (
            <Button
              appearance="secondary"
              shape="square"
              disabled={
                !convertStringToArray(
                  process.env.REACT_APP_DISABLE_CTA_BUTTON
                ).includes("client_outgoings_get_default")
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    master_table: true,
                    customerId: props.activeCustomerId,
                    table_type: "outgoings",
                    reload: () => {
                      setReloadRequired(reloadRequired + 1);
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Close",
                    },
                    apply: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Apply",
                      type: "primary",
                    },
                  })
                );
              }}
            >
              Get Default Expense Categories
            </Button>
          ) : (
            ""
          )}
          {context === "CONTACTS_CLIENT_ACTION" &&
          checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) &&
          !convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
            "contacts_client_action_LSA"
          ) ? (
            <Button
              size="medium"
              style={{ marginRight: "5px" }}
              appearance="outline"
              disabled={
                !convertStringToArray(
                  process.env.REACT_APP_DISABLE_CTA_BUTTON
                ).includes("contacts_client_action_LSA")
                  ? false
                  : true
              }
              onClick={() => {
                setLoadStandardActionPopup(true);
                // dispatch(
                //   setDialogModalOptions({
                //     open: true,
                //     load_action: true,
                //     customerId: props.activeCustomerId,
                //     reload: () => {
                //       setReloadRequired(reloadRequired + 1);
                //     },
                //     cancel: {
                //       onclick: () => {
                //         dispatch(setDialogModalOptions({ open: false }));
                //       },
                //       label: "Close",
                //     },
                //     apply: {
                //       onclick: () => {
                //         // dispatch(
                //         //   setDialogModalOptions({ open: false })
                //         // );
                //       },
                //       label: "Save",
                //       type: "primary",
                //     },
                //   })
                // );
              }}
            >
              Load Standard Actions
            </Button>
          ) : (
            ""
          )}
          {checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) && (
            <Button
              className="asc-button-primary"
              style={{ marginRight: 2, marginBottom: 6 }}
              appearance="primary"
              size="medium"
              icon={<Add16Filled />}
              onClick={() => {
                changeMode("add");
              }}
            >
              Add New
            </Button>
          )}
        </div>
      )}
      {mode && mode !== "" && (
        <>
          {["edit", "add"].includes(mode) && (
            <div className={classes.btnwrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                <Label
                  size="medium"
                  weight="semibold"
                  className={classes.message}
                >
                  {formMode !== "" && (
                    <>
                      <span>**</span> {formMode} <span>**</span>
                    </>
                  )}
                </Label>
              </div>
              <div>
                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={
                    past !== undefined && past.length === 0 ? true : false
                  }
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                }}
                disabled={!isFormDataUpdated}
              >
                Reset All
              </Button>
              {/* <div>
                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={!isFormDataUpdated}
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                disabled={!isFormDataUpdated}
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                }}
              >
                Reset All
              </Button> */}
              {context === "ADDRESS_BOOK" && (
                <Button
                  appearance="secondary"
                  shape="square"
                  onClick={() => {}}
                >
                  Set as Client Address
                </Button>
              )}
            </div>
          )}
          {props.mode && props.mode === "edit" && (
            // <>
            //  {checkUserPermission(
            //   loggedUser,
            //   UserPermissionsList.ALLOW_UPDATE_CLIENT
            // ) && (

            <Button
              appearance="transparent"
              size="medium"
              icon={<Delete16Regular />}
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    content_line_1: "Do you want to delete?",
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "No",
                      type: "primary",
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Yes",
                    },
                  })
                );
              }}
            ></Button>

            // )}
            // </>
          )}
          {mode && mode === "view" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                Record {currentRecord} of {dataList.length}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                {context === "CLIENT_ATTITUDE_TO_RISK" &&
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("client_attitude_get_default") ? (
                  <Button
                    appearance="secondary"
                    shape="square"
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("client_attitude_get_default")
                        ? false
                        : true
                    }
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          customerId: props.activeCustomerId,
                          master_table: true,
                          table_type: "attitudeToRisk",
                          reload: () => {
                            setReloadRequired(reloadRequired + 1);
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Close",
                          },
                          apply: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Apply",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  >
                    Get Default Categories
                  </Button>
                ) : (
                  ""
                )}
                <>
                  {/* {console.log(
                    "process.env.REACT_APP_HIDE_CTA_BUTTON",
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  )} */}
                </>
                {context === "CLIENT_OBJECTIVES" &&
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("client_objectives_get_default") ? (
                  <Button
                    appearance="secondary"
                    shape="square"
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("client_objectives_disable_get_default")
                        ? false
                        : true
                    }
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          master_table: true,
                          table_type: "objectives",
                          customerId: props.activeCustomerId,
                          reload: () => {
                            setReloadRequired(reloadRequired + 1);
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Close",
                          },
                          apply: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Apply",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  >
                    Get Default Objectives
                  </Button>
                ) : (
                  ""
                )}
                {context === "CLIENT_INCOME" &&
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("client_income_categories") ? (
                  <Button
                    style={{ marginRight: 2, marginBottom: 6 }}
                    size="medium"
                    appearance="secondary"
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          master_table: true,
                          customerId: props.activeCustomerId,
                          table_type: "income",
                          reload: () => {
                            setReloadRequired(reloadRequired + 1);
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Close",
                          },
                          apply: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Apply",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  >
                    Get Default Income Categories
                  </Button>
                ) : (
                  ""
                )}
                {context === "CLIENT_OUTGOINGS" &&
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("client_outgoings_get_default") ? (
                  <Button
                    appearance="secondary"
                    style={{ marginRight: 2, marginBottom: 6 }}
                    size="medium"
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          master_table: true,
                          customerId: props.activeCustomerId,
                          table_type: "outgoings",
                          reload: () => {
                            setReloadRequired(reloadRequired + 1);
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Close",
                          },
                          apply: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Apply",
                            type: "primary",
                          },
                        })
                      );
                    }}
                  >
                    Get Default Expense Categories
                  </Button>
                ) : (
                  ""
                )}
                {context === "CONTACTS_CLIENT_ACTION" &&
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) &&
                !convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("contacts_client_action_LSA") ? (
                  <Button
                    size="medium"
                    style={{ marginRight: "5px" }}
                    appearance="outline"
                    onClick={() => {
                      setLoadStandardActionPopup(true);
                      // dispatch(
                      //   setDialogModalOptions({
                      //     open: true,
                      //     load_action: true,
                      //     customerId: props.activeCustomerId,
                      //     reload: () => {
                      //       setReloadRequired(reloadRequired + 1);
                      //     },
                      //     cancel: {
                      //       onclick: () => {
                      //         dispatch(setDialogModalOptions({ open: false }));
                      //       },
                      //       label: "Close",
                      //     },
                      //     apply: {
                      //       onclick: () => {
                      //         // dispatch(
                      //         //   setDialogModalOptions({ open: false })
                      //         // );
                      //       },
                      //       label: "Save",
                      //       type: "primary",
                      //     },
                      //   })
                      // );
                    }}
                  >
                    Load Standard Actions
                  </Button>
                ) : (
                  ""
                )}{" "}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Button
                    className="asc-button-primary"
                    style={{ marginRight: 2, marginBottom: 6 }}
                    appearance="primary"
                    size="medium"
                    icon={<Add16Filled />}
                    onClick={() => {
                      changeMode("add");
                    }}
                  >
                    Add New
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Button
                    style={{ marginRight: 2, marginBottom: 6 }}
                    icon={<Edit24Filled />}
                    size="medium"
                    onClick={() => {
                      changeMode("edit");
                    }}
                  >
                    Edit
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Button
                    icon={<Delete16Regular />}
                    style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                    size="medium"
                    onClick={() => {
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1: "Do you want to delete?",
                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "No",
                            type: "primary",
                          },
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Yes",
                          },
                        })
                      );
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          )}
          {formError && formError.length > 0 && (
            <FormErrorMessage
              errorMessages={formError}
              onDismiss={() => setFormError([])}
            />
          )}
        </>
      )}
      <div style={{ maxHeight: "calc(100vh - 390px)", overflow: "auto" }}>
        {mode && mode !== "" && (
          <FormContent
            {...{
              formik,
              formFieldData,
              mode,
              handleOnChange,
              isAdminForm,
              optionList,
              customHandleChange,
              selectFieldOptions,
              newSelectOptionAdded,
              updateConsultantName,
              updateCommissionType,
              context: props.context,
              customer_id: props.activeCustomerId,
              uploadedFiles,
              setUploadedFiles,
              removeFile,
            }}
          />
        )}
        {mode !== "view" && mode !== "" && (
          <div className={classes.buttonWrapper}>
            <Button
              className="asc-button-primary"
              appearance="primary"
              disabled={isFormLoading || !isFormDataUpdated}
              onClick={() => {
                if (isFormDataUpdated) {
                  if (
                    props.activePartnerId === null &&
                    formik.values.owner === "1" &&
                    context === "CLIENT_INCOME"
                  ) {
                    setPartnerDialogPopup(true);
                  } else {
                    formik.handleSubmit();
                  }
                }
              }}
            >
              Save
            </Button>

            {props.tabMenuIndex &&
              props.tabMenuIndex.next !== -1 &&
              !props.isContactHistory && (
                <Button
                  onClick={() => {
                    setNextAction("next");
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    isFormDataUpdated
                      ? formik.handleSubmit()
                      : setNextAction("next");
                  }}
                  disabled={isFormLoading || !isFormDataUpdated}
                >
                  Save & Next
                </Button>
              )}

            <Button
              onClick={() => {
                if (isFormDataUpdated) {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1:
                        "We have detected an attempt to move away from the current page.",
                      content_line_2:
                        "This would cause all changes made to be lost",
                      cancel: {
                        onclick: () => {
                          dispatch(
                            setDialogModalOptions({
                              open: false,
                            })
                          );
                          // props.setFormUpdated(false);
                          dispatch(resetFormData());
                          formik.resetForm();
                          changeMode("view");
                          if (props.isContactHistory) {
                            dispatch(setDialogModalOptions({ open: false }));
                          }
                        },
                        label: "Leave the Page",
                      },
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Stay on Page",
                        type: "primary",
                      },
                    })
                  );
                } else {
                  formik.resetForm();
                  dataList.length === 0 ? changeMode("") : changeMode("view");
                  if (props.isContactHistory) {
                    dispatch(setDialogModalOptions({ open: false }));
                  }
                }
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        {dataList && dataList.length > 0 ? (
          <div>
            <TableDataList
              {...{
                changeMode,
                handleRowAction,
                mode,
                context: props.context,
                data: dataList,
                selectedRowItem,
                setCurrentRecord,
                tableId: props.tableId,
                isContactHistory: props.isContactHistory,
                customerId: props.activeCustomerId,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: mode === "" ? 200 : 0,
            }}
          >
            {mode === "" && (
              <>
                {" "}
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </>
            )}
          </div>
        )}
      </div>

      <Dialog open={loadStandardActionPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <LoadStandardAction
                selectedLoadStandardActions={selectedLoadStandardActions}
                loadActionSelected={loadActionSelected}
                loadStandardActionDate={loadStandardActionDate}
                setLoadStandardActionDate={setLoadStandardActionDate}
                type="client"
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedLoadStandardActions([]);
                    setLoadStandardActionPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={addLoadStandardActions}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <PartnerDetailPopup
        notify={props.notify}
        mode={mode}
        partnerDialogPopup={partnerDialogPopup}
        setPartnerDialogPopup={setPartnerDialogPopup}
        isFormDataUpdated={isFormDataUpdated}
        formError={formError}
        setFormError={setFormError}
        newSelectOptionAdded={newSelectOptionAdded}
        activeCustomerId={props.activeCustomerId}
        activePartnerId={props.activePartnerId}
        setActivePartnerId={props.setActivePartnerId}
        customHandleChange={customHandleChange}
        reloadDetail={props.reloadDetail}
      />
    </>
  );
};

export default FormContainerWithList;
