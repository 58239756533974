export const ApiConstants = {
  auth: {
    login: "/asc-auth/login",
    login2: "asc-auth/login",
    authUser: "/asc-auth/auth",
    logout: "asc-auth/auth/:user_id/logout",
  },
  client: {
    // list: "/asc/api/v1.0/client",
    // detail: "/asc/api/v1.0/client",
    // add: "/asc/api/v1.0/client",
    // update: "/asc/api/v1.0/client",
    listAll: "/client",
    detail: "/client",
    add: "/client",
    update: "/client",
  },
  extract: {
    getList: "/client/get-locate-and-extract-tree",
    add: "/client/search",
    getExtract: "/client/search",
    applySearch: "/client",
    // getExtractbyId:"/client/search"
  },
  vulnerability: {
    add: "/client/vulnerability",
    get: "/client/vulnerability/customer",
    update: "/client/vulnerability",
    getDriver: "/client/vulnerability/driver",
    getCharacteristics: "client/vulnerability/characteristics",
  },
  clientUserDefined: {
    add: "/client/userdefined/bulkinsert/value",
    get: "/client/userdefined/value",
    update: "/client/userdefined/bulkupdate/value",
  },

  actionList: {
    list: "/case/actionlist/user",
    getConsultant: "/case/actionlist/consultant/user",
    getAssignee: "/case/actionlist/assignee/user",
  },
  contactHistory: {
    list: "/case/contact/customer",
  },
  clientActionList: {
    post: "/actionlist/appointment",
    list: "/actionlist/appointment",
  },
  caseActionList: {
    post: "/actionlist/business",
    list: "/actionlist/business",
  },
  report: {
    list: "/report",
  },
  reportList: {
    list: "/report/category?type=Report",
  },
  caseAction: {
    list: "/case/business/customer",
  },
  caseSummaryAsset: {
    list: "/case/asset/casesummary/customer",
  },
  caseSummaryLiability: {
    list: "/case/liability/casesummary/customer",
  },
  caseSummaryList: {
    list: "/case/case-summary/customer",
  },
  commission: {
    addCommission: "/case/commission",
    getCommission: "/case/commission",
    updateCommission: "/case/commission",
  },
  asset: {
    // list: "/asc/api/v1.0/asset",
    list: "/case/asset/customer",
    add: "/case/asset",
    update: "/case/asset",
    getDetail: "/case/asset",
    addPayment: "/case/payments",
    getAssetPayments: "/case/payments/case",
    updatePayment: "/case/payments",
    getWithdrawals: "case/withdrawal/case",
    addNewWithdrawals: "/case/withdrawal",
    updateWithdrawals: "/case/withdrawal",
    getActions: "/case/business",
    // getActions: "/case/business/customer",
    addActions: "/case/business",
    updateActions: "/case/business",
  },
  policy: {
    list: "/case/policy/customer",
    add: "/case/policy",
    update: "/case/policy",
    getDetail: "/case/policy",
    addPayment: "/case/payments",
    getPolicyPayments: "/case/payments/case",
    updatePayment: "/case/payments",
    getWithdrawals: "/case/withdrawal/case",
    addNewWithdrawals: "/case/withdrawal",
    updateWithdrawals: "/case/withdrawal",
    getActions: "/case/business",
    addActions: "/case/business",
    updateActions: "/case/business",
  },
  liability: {
    list: "/case/liability/customer",
    add: "/case/liability",
    update: "/case/liability",
    getDetail: "/case/liability",
    addPayment: "/case/payments",
    getLiabilityPayments: "/case/payments/case",
    updatePayment: "/case/payments",
    getActions: "/case/business",
    addActions: "/case/business",
    updateActions: "/case/business",
  },
  outgoings: {
    add: "/assystcashflow/expense",
    list: "/assystcashflow/expense/customer",
    update: "/assystcashflow/expense",
  },
  income: {
    add: "/assystcashflow/income",
    list: "/assystcashflow/income/customer",
    update: "/assystcashflow/income",
  },
  dependant: {
    add: "/client",
    list: "client/depentant",
    update: "/client",
  },
  addressBook: {
    add: "/client",
    list: "/client/addressbook/customer",
    update: "/client",
  },
  attitudeToRisk: {
    add: "/client",
    list: "/client/attituderisk/customer",
    update: "/client",
  },
  clientContacts: {
    clientAction: {
      add: "/client",
      list: "/client/timeallocationbycustomer",
      update: "/client",
    },
    notes: {
      add: "/client/notes",
      list: "/client/notes",
      update: "/client",
      getRecent: "/client/note/notebycustomerid",
      getById: "/client/note",
    },
    timeAllocation: {
      add: "/client",
      list: "/client/timeallocationbycustomer",
      update: "/client",
    },
  },
  clientDocument: {
    getList: "/client/list-of-folders",
    addFolder: "/client/create-folder",
    addDocument: "/client/upload-file",
  },
  FactFind: {
    add: "/client/notes",
    list: "/client/notes",
    update: "/client",
  },
  contactClientAction: {
    add: "/client",
    list: "/client/appointmentbycustomer",
    update: "/client/appointment",
  },
  feesCharges: {
    add: "/client",
    list: "/client/commissionbycustomer/customer",
    update: "/client",
  },
  fund: {
    add: "/case/fund",
    list: "/case/fund",
    update: "/case/fund",
  },
  valuation: {
    addvaluation: "/case/valuation",
    getvaluation: "/case/valuation/case",
    updatevaluation: "/case/valuation",
  },
  notification: {
    add: "/notification/create-template",
    list: "/notification/get-template",
    clientList: "/client/list/all",
    // createContent: "/notification/create-content",
    createContent: "/notification",
    sendEmail: "/notification/create-contents",
    sendBulkEmail:"/user/emailbulk",
    // sendEmail: "/notification/send-email",
    getPreview: "/client/customers",
  },
  credentials: {
    add: "/user/port",
    list: "/user/info",
  },
  emailCredentials: {
    add: "/user/appasswrd",
    // list: "/user/info",
  },
  getDefaultAttitudeToRisk: {
    add: "/client/default/attituderisk",
  },
  getDefaultIncome: {
    add: "/assystcashflow/income/default ",
  },
  getDefaultexpense: {
    add: "/assystcashflow/expense/default ",
  },
  getDefaultobjective: {
    add: "/client/defaultobjectivecategory",
  },
  loadStandardActions: {
    add: "/case/business/default",
    addclientAction: "client/appointment/default",
  },
  getTrackingAction: {
    list: "/masterdata/loadstandard/case",
    listClient: "/masterdata/loadstandard/client",
  },
  exportToExcel: "/asc/api/v1.0/",
  formSettings: "/formSettings",
  userProfile: {
    getDetail: "/user/profile",
    updateDetail: "/user/profile",
  },

  document: "/client/document",

  systemManager: {
    manageUsers: {
      getPermissions: "/user/auth/roles",
      getUsers: "/user",
      getUserlist: "/user/limit-user",
      createUser: "/user",
      updateUser: "user/:user_id",
      getDetails: "/user/auth/:user_id",
    },
    commissionType: {
      add: "masterdata/commission/commissiontypes",
      list: "masterdata/commission/commissiontypes",
      update: "masterdata/commission/commissiontypes",
    },
    commissionRule: {
      add: "masterdata/commission/commissionrule",
      list: "masterdata/commission/commissionrule",
      update: "masterdata/commission/commissionrule",
    },
    expenseCategory: {
      add: "masterdata/transactions/expensecategories",
      list: "masterdata/transactions/expensecategories",
      update: "masterdata/transactions/expensecategories",
      delete:"masterdata/delete/Expense-Category"
    },
    incomeCategory: {
      add: "masterdata/transactions/incomecategories",
      list: "masterdata/transactions/incomecategories",
      update: "masterdata/transactions/incomecategories",
    },
    attitudeToRiskcategory: {
      add: "masterdata/attituderisk/categories",
      list: "masterdata/attituderisk/categories",
      update: "masterdata/attituderisk/categories",
    },
    attitudeToRiskRating: {
      add: "masterdata/attituderisk/ratings",
      list: "masterdata/attituderisk/ratings",
      update: "masterdata/attituderisk/ratings",
    },
    objectives: {
      add: "masterdata/objectives",
      list: "masterdata/objectives",
      update: "masterdata/objectives",
    },
    providers: {
      add: "masterdata/providers",
      list: "masterdata/providers",
      update: "masterdata/providers",
      providerList: "masterdata/providers", 
      delete:"masterdata/delete/provider"
    },
    userDefined: {
      add: "masterdata/userdefined/field",
      list: "masterdata/userdefined/field",
      update: "masterdata/userdefined/field",
      delete: "masterdata/userdefined/field/delete",
    },
    loadTrackingAction: {
      add: "masterdata/standardtracking",
      list: "masterdata/standardtracking/case",
      update: "masterdata/standardtracking",
      listClient: "masterdata/standardtracking/client",
      listCaseAction: "masterdata/standardtracking/case",
    },
  },
  selectionList: {
    getOptions: "/masterdata/option",
    addOptions: "/masterdata/option",
  },
  commissionModule: {
    commissionClient: {
      add: "/client/create_commission",
      list: "/client/commission",
      update: "/client/update_commission",
    },
  },
};

export const UserPermissionsList = {
  ALLOW_VIEW_CLIENT: "allow-view-client",
  ALLOW_ADD_CLIENT: "allow-add-client",
  VIEW_ALL_CLIENT: "view-all-client",
  ALLOW_RESTRICTED_CLIENT: "allow-restricted-client",
  ALLOW_UPDATE_CLIENT: "allow-update-client",
  LIMIT_CLIENTS_FOR_USER: "limit-clients-for-user",
  ALLOW_DELETE_CLIENT: "allow-delete-client",
  ALLOW_CLIENT_REPORTS: "allow-client-reports",
  ALLOW_REASSIGN_ACTIONS: "allow-reassign-actions",
  ALLOW_CLIENT_UPLOADS: "allow-client-uploads",
  ALLOW_CLIENT_EXPORTS: "allow-client-exports",
  ALLOW_ADD_USER: "allow-add-user",
  ALLOW_UPDATE_USER: "allow-update-user",
  ALLOW_VIEW_USER: "allow-view-user",
  ALLOW_DELETE_USER: "allow-delete-user",
  ALLOW_UPDATE_MASTER_DATA: "allow-update-master-data",
};

export const apiUrls = {
  CUSTOMER_BASE_URL: "https://dev-middleware.assyst.cloud/",
  // CUSTOMER_BASE_URL: "https://qa-middleware.assyst.cloud/",
  // CUSTOMER_BASE_URL: "http://localhost:4000/",
};
